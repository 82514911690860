import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { theme } from '../../../theme/theme'
import { BodyLarge, Title2 } from '../../../components/styles/Typography'
import { Analysis } from './Analysis'
import { HealthPlan } from './HealthPlan'
import { TabData, Tabs } from '../../../components/Tabs'
import styled from 'styled-components'
import { useSelectScreeningResultStatus } from '../../../state/selectors'
import { useParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { ScreeningResultStatus } from '../../../api/ester-b2b-api/private/screeningResult/types'

const tabsData: TabData[] = [
  { label: 'Din analys', content: <Analysis />, slug: 'analys' },
  { label: 'Din hälsoplan', content: <HealthPlan />, slug: 'halsoplan' },
]
export const YourHealthIndex = () => {
  const selectedScreeningResultStatus = useSelectScreeningResultStatus()
  const { id } = useParams()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
      })
    }
  }, [id])

  return (
    <Container ref={containerRef}>
      <ComponentLayout>
        <StyledTitle2>Resultat och rekommendationer</StyledTitle2>
      </ComponentLayout>

      {selectedScreeningResultStatus === ScreeningResultStatus.HANDLED ? (
        <Tabs tabs={tabsData} />
      ) : (
        <ComponentLayout>
          <BodyLarge>När du är klar med screeningen kommer din personliga hälsoplan visas här.</BodyLarge>
        </ComponentLayout>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
