import { FC } from 'react'
import { BodyLarge } from './styles/Typography'
import { datadogRum } from '@datadog/browser-rum'
import { env } from '../utils/environments/environmentVariables'

export const ErrorGuard: FC = () => {
  if (env.isProd('VITE_NODE_ENV')) {
    datadogRum.addError(`Failed to fetch data at ${window.location.href}`)
    return (
      <BodyLarge>Någonting gick fel! Vänligen försök igen eller kontakta support på support@estercare.com</BodyLarge>
    )
  } else {
    // eslint-disable-next-line no-console
    console.log('Failed to fetch data')
    return (
      <BodyLarge>Någonting gick fel! Vänligen försök igen eller kontakta support på support@estercare.com</BodyLarge>
    )
  }
}
