import styled from 'styled-components'
import { BlockItem } from '../../components/BlockItem'
import { Grid } from '../../components/Grid'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Title1, Title5 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { Link } from 'react-router-dom'

export const ComponentLibrary = () => {
  return (
    <ComponentLayout>
      <StyledTitle1>Component Library</StyledTitle1>
      <ComponentLayout>
        <Grid $columns={2}>
          <Link to="./colors">
            <BlockItem padding={theme.spacing.medium} backgroundColor={theme.color.white}>
              <Title5>Colors</Title5>
            </BlockItem>
          </Link>
          <Link to="./typography">
            <BlockItem padding={theme.spacing.medium} backgroundColor={theme.color.white}>
              <Title5>Typography</Title5>
            </BlockItem>
          </Link>
          <Link to="./buttons">
            <BlockItem padding={theme.spacing.medium} backgroundColor={theme.color.white}>
              <Title5>Buttons</Title5>
            </BlockItem>
          </Link>
          <Link to="./inputs">
            <BlockItem padding={theme.spacing.medium} backgroundColor={theme.color.white}>
              <Title5>Inputs</Title5>
            </BlockItem>
          </Link>
          <Link to="./grid">
            <BlockItem padding={theme.spacing.medium} backgroundColor={theme.color.white}>
              <Title5>Grid</Title5>
            </BlockItem>
          </Link>
          <Link to="./icons">
            <BlockItem padding={theme.spacing.medium} backgroundColor={theme.color.white}>
              <Title5>Icons</Title5>
            </BlockItem>
          </Link>
          <Link to="./contentful-blocks">
            <BlockItem padding={theme.spacing.medium} backgroundColor={theme.color.white}>
              <Title5>Contentful blocks</Title5>
            </BlockItem>
          </Link>
        </Grid>
      </ComponentLayout>
    </ComponentLayout>
  )
}

export const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
