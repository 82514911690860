import styled from 'styled-components'
import { theme } from '../../theme/theme'

interface Props<T> {
  component: string
  options: readonly T[]
  setChange: (arg: T) => void
  defaultValue?: T
  color?: string
  backgroundColor?: string
}

export const Radios = <T,>({ options, component, setChange, defaultValue, backgroundColor, color }: Props<T>) => {
  return (
    <form>
      <RadiosContainer $backgroundColor={backgroundColor}>
        {options.map((option, index) => {
          return (
            <>
              <StyledLabel key={index} $color={color}>
                {String(option)}
              </StyledLabel>
              <Radio
                type="radio"
                value={String(option)}
                onChange={(event) => setChange(event.target.value as T)}
                name={component}
                defaultChecked={defaultValue ? option === defaultValue : index === 0}
              />
            </>
          )
        })}
      </RadiosContainer>
    </form>
  )
}

const RadiosContainer = styled.div<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'none'};
  color: ${theme.color.black};
  width: 100%;
  padding: ${theme.spacing.small}px ${theme.spacing.mediumLarge}px;
  display: flex;
  align-items: center;
  color: ${theme.color.white};

  input {
    margin-right: ${theme.spacing.mediumLarge}px;
  }
`

const StyledLabel = styled.label<{ $color?: string }>`
  color: ${({ $color }) => $color || theme.color.black};
`

const Radio = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid ${theme.color.grey3};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: ${theme.spacing.small}px;
  cursor: pointer;

  &:checked {
    border: 2px solid ${theme.color.plumMid};
  }

  &:checked::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: ${theme.color.plumMid};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
