import { useGetEntryById, useGetGeneralPage } from '../../api/contentful/contentfulQueries'
import { DynamicBlock } from '../../components/DynamicBlock'
import { PreviewGuard } from '../../components/PreviewGuard'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, Title1 } from '../../components/styles/Typography'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useSelectFirstName, useSelectScreeningResultStatus } from '../../state/selectors'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { ScreeningResultStatus } from '../../api/ester-b2b-api/private/screeningResult/types'
import { TextWithImageCard } from '../../components/TextWithImageCard'
import { isITextWithImageBlock } from '@estercare/ester-shared'

export const Home = () => {
  const firstName = useSelectFirstName()
  const homePage = useGetGeneralPage('home')
  const screeningBlock = useGetEntryById('7s3RkHs2YxV61gDavGwJku').data
  const healthPlanBlock = useGetEntryById('5ZSNxVyDnV0jZNMhqkkjmM').data

  const selectedScreeningResultStatus = useSelectScreeningResultStatus()
  const hasHealthPlan = selectedScreeningResultStatus === ScreeningResultStatus.HANDLED
  const textWithImageBlock = hasHealthPlan ? healthPlanBlock : screeningBlock

  const homeContent = homePage?.data?.items?.[0]?.fields

  if (homePage.isLoading) return <LoadingSpinner />
  if (!homeContent) return <PreviewGuard />

  const { description, contentBlocks } = homeContent

  return (
    <>
      <ComponentLayout paddingBottom={0}>
        <HeadingContainer>
          <StyledTitle1>{firstName ? `Hej, ${firstName}!` : 'Hej!'}</StyledTitle1>
          <BodyLarge>{description}</BodyLarge>
        </HeadingContainer>
      </ComponentLayout>
      <ComponentLayout paddingBottom={0}>
        {isITextWithImageBlock(textWithImageBlock) && <TextWithImageCard {...textWithImageBlock.fields} />}
      </ComponentLayout>
      {Array.isArray(contentBlocks) &&
        contentBlocks.map((block, index) => (
          <ComponentLayout key={index} paddingBottom={0}>
            <DynamicBlock block={block} />
          </ComponentLayout>
        ))}
    </>
  )
}

const HeadingContainer = styled.div`
  max-width: 640px;
`

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
