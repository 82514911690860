import { css } from 'styled-components'

export const boxShadow = css`
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.05);
`

export const boxShadowDarker = css`
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
`

export const inputShadow = css`
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
`
