import React, { useEffect, useState } from 'react'
import {
  useSelectUserGender,
  useSelectUserZipcode,
  useSelectUserBirthYear,
  useSelectFirstName,
  useSelectUserId,
} from '../../state/selectors'
import { Modal } from '../Modal'
import { BodyLargeMedium, BodySmall, Title3 } from '../styles/Typography'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { OnClickButton } from '../buttons/onclick/OnClickButton'
import { useSupplementaryDataForm } from './useSupplementaryDataForm'
import { FormTypes, SelectOption } from './types'
import { TextInput } from '../inputs/Text'
import { SelectInput } from '../inputs/Select'
import { ZipCodeInput } from '../inputs/ZipCode'

export const SupplementaryDataModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const userId = useSelectUserId()
  const userGender = useSelectUserGender()
  const userZipCode = useSelectUserZipcode()
  const userBirthYear = useSelectUserBirthYear()
  const firstName = useSelectFirstName()

  const { formData, formErrors, handleInputChange, onSubmit } = useSupplementaryDataForm(
    {
      birthYear: userBirthYear?.toString() || '',
      gender: userGender || '',
      zipCode: userZipCode?.toString() || '',
    },
    userId || null,
    () => setShowModal(false)
  )

  const genderOptions: SelectOption[] = [
    { value: 'F', label: 'Kvinna' },
    { value: 'M', label: 'Man' },
    { value: 'U', label: 'Föredrar att inte uppge' },
  ]

  useEffect(() => {
    if (!userGender || !userZipCode || !userBirthYear) {
      setShowModal(true)
    }
  }, [userBirthYear, userGender, userZipCode])

  if (!showModal) return null

  return (
    <Modal disableClose>
      <StyledTitle3>Hej, {firstName}!</StyledTitle3>
      <BodyLargeMedium>Välkommen till EsterCare för företag</BodyLargeMedium>
      <BodySmall>För att komma igång behöver vi veta lite mer om dig.</BodySmall>
      <InputWrapper>
        <TextInput
          backgroundColor={theme.color.beigeLight}
          value={formData.birthYear}
          label="Födelseår"
          placeholder="YYYY"
          maxLength={4}
          onChange={handleInputChange(FormTypes.BIRTH_YEAR)}
          error={formErrors?.find((error) => error.formType === FormTypes.BIRTH_YEAR)?.errorMessage}
        />
      </InputWrapper>
      <InputWrapper>
        <SelectInput
          backgroundColor={theme.color.beigeLight}
          label="Biologiskt kön"
          options={genderOptions}
          value={formData.gender}
          onChange={handleInputChange(FormTypes.GENDER)}
          placeholder="Välj ett alternativ"
        />
      </InputWrapper>
      <InputWrapper>
        <ZipCodeInput
          value={formData.zipCode}
          backgroundColor={theme.color.beigeLight}
          onChange={handleInputChange(FormTypes.ZIP_CODE)}
          error={formErrors?.find((error) => error.formType === FormTypes.ZIP_CODE)?.errorMessage}
        />
      </InputWrapper>
      <ButtonWrapper>
        <OnClickButton
          disabled={!formData.birthYear || !formData.gender || !formData.zipCode}
          buttonColor={theme.color.plumMid}
          text="Fortsätt"
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </Modal>
  )
}

const StyledTitle3 = styled(Title3)`
  && {
    margin-bottom: ${theme.spacing.xsmall}px;
  }
`

const InputWrapper = styled.div`
  margin-top: ${theme.spacing.mediumLarge}px;
`

const ButtonWrapper = styled.div`
  margin-top: ${theme.spacing.mediumLarge}px;
  button {
    width: 100%;
  }
`
