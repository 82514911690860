import styled from 'styled-components'
import { OptionButton } from '../buttons/OptionButton'
import { useState } from 'react'
import { theme } from '../../theme/theme'

type BooleanInputProps = {
  options: string[]
  color?: string
  backgroundColor?: string
}

export const BooleanInput = ({ options, color, backgroundColor }: BooleanInputProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  return (
    <BooleanContainer>
      {options.map((option, index) => (
        <OptionButton
          key={index}
          onClick={() => setSelectedOption(option)}
          option={option}
          type="boolean"
          backgroundColor={backgroundColor}
          color={color}
          selected={selectedOption === option}
        />
      ))}
    </BooleanContainer>
  )
}

const BooleanContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xsmall}px;
`
