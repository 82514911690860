import { FC } from 'react'
import { theme } from '../../theme/theme'
import styled from 'styled-components'
import { inputShadow } from '../../theme/shadows'
import { BodySmall } from '../styles/Typography'

type OptionButtonTypes = 'boolean' | 'singleOption' | 'multipleOptions'

interface OptionButtonProps {
  onClick: () => void
  color?: string
  backgroundColor?: string
  disabled?: boolean
  option: string
  type: OptionButtonTypes
  selected?: boolean
}

export const OptionButton: FC<OptionButtonProps> = ({
  onClick,
  color = theme.color.black,
  backgroundColor = theme.color.white,
  disabled,
  option,
  selected,
}) => {
  return (
    <Button onClick={onClick} $backgroundColor={backgroundColor} disabled={disabled} $selected={selected}>
      <StyledBodySmall $color={color}>{option}</StyledBodySmall>
      {/* Add support here for multiple options */}
    </Button>
  )
}

const Button = styled.div<{ disabled?: boolean; $backgroundColor: string; $selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 100%;
  border: ${({ $selected }) => ($selected ? `1px solid ${theme.color.plumMid}` : `1px solid ${theme.color.grey2}`)};
  border-radius: 16px;
  background-color: ${({ disabled, $backgroundColor }) => (disabled ? theme.color.grey2 : $backgroundColor)};
  margin-right: ${theme.spacing.tiny}px;
  cursor: pointer;
  ${inputShadow}

  &:disabled {
    background: ${theme.color.grey2};
    border-color: ${theme.color.grey2};
    color: ${theme.color.grey3};
    cursor: not-allowed;
  }
`

const StyledBodySmall = styled(BodySmall)<{ $color: string }>`
  color: ${({ $color }) => $color};
`
