import { FC } from 'react'
import styled from 'styled-components'
import { IStaffListFields } from '@estercare/ester-shared'
import { theme } from '../../theme/theme'
import { StaffScroller } from './StaffScroller'

import { Title3 } from '../styles/Typography'

export const StaffList: FC<Partial<IStaffListFields>> = ({ title, specialists, backgroundColor }) => {
  const color = backgroundColor === 'plum' ? theme.color.white : theme.color.black

  return (
    <Container>
      <StyledTitle3 $color={color}>{title}</StyledTitle3>
      <StaffScroller
        specialists={specialists?.fields.specialists}
        fullWidthStyle={true}
        backgroundColor={backgroundColor}
      />
    </Container>
  )
}

const StyledTitle3 = styled(Title3)<{ $color: string }>`
  text-align: center;
  margin-bottom: ${theme.spacing.large}px;
  color: ${({ $color }) => $color};
`
const Container = styled.div`
  margin-top: ${theme.spacing.xxlarge}px;
`
