import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodySmallMedium, BodySmall } from './styles/Typography'
import { FC } from 'react'
import { Success } from './icons'

interface Steps {
  title: string
  description: string
  content?: JSX.Element
}

interface Text {
  title: string
  description: string
}

type Props = { steps: Steps[]; text?: never } | { steps?: never; text: Text }

export const ServiceGuide: FC<Props> = ({ steps, text }: Props) => {
  return (
    <ServiceStepContainer>
      {text ? (
        <StepContainer>
          <Success fill={theme.color.white} size={64} />
          <TextContainer className="claimedOrComplete">
            <BodySmallMedium>{text.title}</BodySmallMedium>
            <BodySmall>{text.description}</BodySmall>
          </TextContainer>
        </StepContainer>
      ) : (
        <>
          {steps.map((step, index) => {
            const { title, description, content } = step
            return (
              <StepContainer key={index}>
                <NumberContainer>
                  <Number>{index + 1}</Number>
                </NumberContainer>
                <TextContainer>
                  <BodySmallMedium>{title}</BodySmallMedium>
                  <BodySmall>{description}</BodySmall>
                  <ContentContainer>{content}</ContentContainer>
                </TextContainer>
              </StepContainer>
            )
          })}
        </>
      )}
    </ServiceStepContainer>
  )
}

const ServiceStepContainer = styled.div`
  display: flex;
  background-color: ${theme.color.plum};
  margin-top: ${theme.spacing.medium}px;
  padding: ${theme.spacing.xxlarge}px;
  border-radius: ${theme.spacing.medium}px;
  justify-content: space-around;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    flex-direction: column;
    padding: ${theme.spacing.medium}px;
  }
`

const StepContainer = styled.div`
  width: 100%;
  padding: ${theme.spacing.small}px;
  color: ${theme.color.white};
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    padding: ${theme.spacing.small}px 0;
  }
`

const TextContainer = styled.div`
  width: 80%;
  padding-left: 16px;
  &.claimedOrComplete {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const ContentContainer = styled.div`
  padding-top: ${theme.spacing.medium}px;
`

const NumberContainer = styled.div`
  color: ${theme.color.plum};
  background-color: ${theme.color.white};
  border-radius: 50%;
  width: ${theme.spacing.xxlarge}px;
  height: ${theme.spacing.xxlarge}px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  text-align: center;
  padding-top: ${theme.spacing.tiny}px;
`

const Number = styled.p`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0px;
  font-family: ${theme.font.heading};
  font-weight: 400;
`
