import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { appConfig } from '../../config'

interface Props {
  children: React.ReactNode
}

export const Auth0ProviderWithNavigate = ({ children }: Props) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={appConfig.auth0.domain}
      clientId={appConfig.auth0.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: appConfig.auth0.audience,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}
