import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 24
const DEFAULT_COLOR = theme.color.plumMid

interface ArrowProps extends IconProps {
  left?: boolean
}

export const Arrow = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, left = false, ...rest }: ArrowProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: left ? 'none' : 'rotate(180deg)' }}
      {...rest}
    >
      <path
        d="M7.47696 16.0898L25.332 16.0898"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4336 22.6673L6.66702 16.0895L12.3482 9.33398"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
