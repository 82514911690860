import styled from 'styled-components'
import { Wave } from '../../../components/wave/Wave'
import { theme } from '../../../theme/theme'
import { Title2 } from '../../../components/styles/Typography'
import { useGetUserHealthSummaryQuery } from '../../../api/ester-b2b-api/private/users/userQueries'
import { MarkdownToReact } from '../../../components/markdown/MardownToReact'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { useSelectFirstName } from '../../../state/selectors'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { isMobile } from '../../../utils/devices'
import { ErrorGuard } from '../../../components/ErrorGuard'
import { StaffList } from '../../../components/staffList/StaffList'
import { useGetEntryById } from '../../../api/contentful/contentfulQueries'
import { isIStaffList } from '@estercare/ester-shared'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { OnClickButton } from '../../../components/buttons/onclick/OnClickButton'
import { useNavigate } from 'react-router-dom'
import { AnalysisLayout } from '../../../components/layouts/AnalysisLayout'

export const Analysis = () => {
  const { data: healthSummary, isLoading: isSummaryLoading, isError: isSummaryError } = useGetUserHealthSummaryQuery()
  const {
    data: staffList,
    isLoading: isStaffListLoading,
    isError: isStaffListError,
  } = useGetEntryById('1FsaWIohHyAMhe8XBz07XE')
  const navigate = useNavigate()
  const firstName = useSelectFirstName()
  const width = useWindowWidth()
  const isMobileDevice = isMobile(width)

  return (
    <>
      {!isMobileDevice && (
        <StyledWaveWrapper>
          <Wave color={theme.color.plum} />
        </StyledWaveWrapper>
      )}
      <AnalysisLayout backgroundColor={theme.color.plum} paddingTop={isMobileDevice ? theme.spacing.xxlarge : 0}>
        <SummaryWrapper>
          {isSummaryLoading ? (
            <LoadingSpinner color={theme.color.white} size={60} />
          ) : (
            <Container>
              <StyledTitle2>Hej, {firstName}!</StyledTitle2>
              {isSummaryError || !healthSummary ? (
                <ErrorGuard />
              ) : (
                healthSummary && <MarkdownToReact markdown={healthSummary} />
              )}
            </Container>
          )}
        </SummaryWrapper>
        <ButtonContainer>
          <OnClickButton
            buttonColor={theme.color.orange}
            onClick={() => navigate(`/portal/din-halsa/halsoplan`)}
            text="Läs din hälsoplan"
          />
        </ButtonContainer>
        {isStaffListError && <PreviewGuard />}
        {isStaffListLoading ? (
          <LoadingSpinner color={theme.color.white} size={60} />
        ) : (
          isIStaffList(staffList) && (
            <StaffList
              title="Möt teamet bakom din hälsoplan"
              specialists={staffList.fields.specialists}
              backgroundColor="plum"
            />
          )
        )}
      </AnalysisLayout>
    </>
  )
}

const SummaryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 355px);
`
const StyledWaveWrapper = styled.div`
  z-index: 0;
  margin-top: -${theme.spacing.xxlarge}px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.xxlarge}px;
`
const Container = styled.div`
  background-color: ${theme.color.white};
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  max-width: 748px;
  padding: ${theme.spacing.xlarge}px ${theme.spacing.mediumLarge}px;
  width: 100%;
  h3:first-of-type {
    margin-top: ${theme.spacing.xsmall}px;
  }
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding: ${theme.spacing.medium}px ${theme.spacing.small}px;
  }
`

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.medium}px;
`
