import styled from 'styled-components'
import { BodyTiny, Title5 } from './styles/Typography'
import { theme } from '../theme/theme'
import { Info } from './icons'
import { Popover } from './Popover'

type Props = {
  title: string
  description?: {
    text: string
    infoText?: string
  }
}

export const ScreeningTitle = ({ title, description }: Props) => {
  const infoText = description?.infoText

  return (
    <Container>
      <Title5>{title}</Title5>
      {description && (
        <DescriptionWrapper $color={infoText ? theme.color.brightBlue : theme.color.black}>
          <BodyTiny>{description.text}</BodyTiny>
          {infoText && (
            <Popover text={infoText}>
              <Info size={theme.fontSize.default} color={theme.color.brightBlue} />
            </Popover>
          )}
        </DescriptionWrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  & > * {
    text-align: left;
  }
`

const DescriptionWrapper = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  color: ${({ $color }) => $color};
  gap: ${theme.spacing.tiny}px;
  & > ${BodyTiny} {
    margin-left: ${theme.spacing.tiny}px;
  }

  & > svg {
    cursor: pointer;
  }
`
