import { isIFAQ } from '@estercare/ester-shared'
import { useGetGeneralPage } from '../../../api/contentful/contentfulQueries'
import { FAQ } from '../../../components/Faq'
import { Header } from '../../../components/Header'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { theme } from '../../../theme/theme'
import { ServiceGuide } from '../../../components/ServiceGuide'
import { useSelectScreening, useSelectScreeningResultStatus } from '../../../state/selectors'
import { ScreeningResultStatus } from '../../../api/ester-b2b-api/private/screeningResult/types'

const screeningTexts = {
  handled: {
    title: 'Din screening är klar',
    description: 'Du hittar din personliga hälsoplan under "Din hälsa". Där kan du se din analys och hälsoplan.',
  },
  completed: {
    title: 'Du har genomfört din screening',
    description: 'Dina resultat färdigställs inom 3 vardagar och du får återkoppling via mail.',
  },
  started: {
    title: 'Fortsätt med din screening',
    description: 'Gör klart din screening för att få din personliga hälsoplan.',
  },
  unstarted: {
    title: 'Starta screeningen',
    description:
      'Upplever du besvär du tror är kopplade till din hormonella och gynekologiska hälsa? Eller vill du kanske se om du kan optimera din kvinnohälsa på något sätt? Gör vår digitala screening!',
  },
}

export const Screening2 = () => {
  const selectedScreening = useSelectScreening()
  const selectedScreeningResultStatus = useSelectScreeningResultStatus()

  const isScreeningHandled = selectedScreeningResultStatus === ScreeningResultStatus.HANDLED
  const isScreeningCompleted = selectedScreening?.completed
  const isScreeningStarted = selectedScreening && !selectedScreening.completed

  const { data: screeningPage, isLoading: screeningPageLoading } = useGetGeneralPage('screening')

  if (screeningPageLoading) return <LoadingSpinner />
  const screeningPageContent = screeningPage?.items?.[0]?.fields

  if (!screeningPageContent) return <PreviewGuard />
  const { title, description, contentBlocks } = screeningPageContent
  if (!title) return <PreviewGuard />

  const getScreeningText = () => {
    if (isScreeningHandled) return screeningTexts.handled
    else if (isScreeningCompleted) return screeningTexts.completed
    else if (isScreeningStarted) return screeningTexts.started
    else return screeningTexts.unstarted
  }

  return (
    <>
      <Header
        title={title}
        description={description}
        backButtonLink="/portal/halsotjanster"
        backgroundColor={theme.color.beigeLight}
      />
      <ComponentLayout paddingTop={0}>
        <ServiceGuide text={getScreeningText()} />
      </ComponentLayout>
      <ComponentLayout backgroundColor={theme.color.beigeLight}>
        {contentBlocks &&
          contentBlocks.map((block, index) => isIFAQ(block) && <FAQ showSupportLink key={index} {...block.fields} />)}
      </ComponentLayout>
    </>
  )
}
