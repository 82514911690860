export enum QueryKeys {
  Ping = 'pingData',
  User = 'userData',
  Users = 'usersData',
  OrganisationUsers = 'organisationUsersData',
  Organisation = 'organisationData',
  Organisations = 'organisationsData',
  Services = 'servicesData',
  Trainings = 'trainingsData',
  //Remove after migration
  AccessCode = 'accessCodeData',
  HealthCalls = 'healthCallsData',
  ScreeningResult = 'screeningResultData',
  HealthSummary = 'healthSummaryData',
  HealthPlan = 'healthPlanData',
  Screening = 'screeningData',
}
