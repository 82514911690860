import styled from 'styled-components'
import { BodyLarge, BodyLargeMedium, BodyXlMedium, RichTextLink } from '../../../components/styles/Typography'
import { Wave } from '../../../components/wave/Wave'
import { theme } from '../../../theme/theme'
import { isMobile } from '../../../utils/devices'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { useGetUserHealthPlanQuery } from '../../../api/ester-b2b-api/private/users/userQueries'
import { Accordion } from '../../../components/Accordion'
import { getModuleTitle, Modules } from '@estercare/ester-shared'
import { MarkdownToReact } from '../../../components/markdown/MardownToReact'
import { ErrorGuard } from '../../../components/ErrorGuard'
import { AnalysisLayout } from '../../../components/layouts/AnalysisLayout'

export const HealthPlan = () => {
  const { data: healthPlan, isLoading: isHealthPlanLoading, isError } = useGetUserHealthPlanQuery()
  const width = useWindowWidth()
  const isMobileDevice = isMobile(width)

  if (isHealthPlanLoading) return <LoadingSpinner color={theme.color.plum} size={60} />

  if (isError || !healthPlan) {
    return <ErrorGuard />
  }

  return (
    <>
      {!isMobileDevice && (
        <StyledWaveWrapper>
          <Wave color={theme.color.plum} />
        </StyledWaveWrapper>
      )}
      <AnalysisLayout backgroundColor={theme.color.plum} paddingTop={isMobileDevice ? theme.spacing.xxlarge : 0}>
        <Container>
          <InnerContainer>
            <StyledBodyXlMedium>Din hälsoplan i korthet</StyledBodyXlMedium>
            <TextContainer>
              <MarkdownToReact markdown={healthPlan.summary} />
            </TextContainer>
            {healthPlan.plan.map((plan, index) => (
              <PlanContainer key={index}>
                <StyledBodyXlMedium>{plan.title}</StyledBodyXlMedium>
                {plan.modules
                  .sort((a, b) => Object.values(Modules).indexOf(a.id) - Object.values(Modules).indexOf(b.id))
                  .map((module, moduleIndex) => (
                    <Accordion key={moduleIndex} title={getModuleTitle(module.id)}>
                      <MarkdownToReact markdown={module.text} />
                    </Accordion>
                  ))}
              </PlanContainer>
            ))}
            <StyledBodyXlMedium>Om du behöver söka vård för dina besvär kan du kontakta:</StyledBodyXlMedium>
            <TextContainer>
              <BodyLarge>
                <StyledListItem>
                  En gynekologmottagning. Observera att det i vissa regioner krävs en remiss för att komma till
                  gynekolog, medan du i andra regioner kan boka tid direkt. Gå in på{' '}
                  <RichTextLink href="https://www.1177.se/hitta-vard/" target="_blank" rel="noopener noreferrer">
                    1177
                  </RichTextLink>{' '}
                  för information om vad som gäller där du bor.
                </StyledListItem>
                <StyledListItem>
                  EsterCares fysiska mottagning. För ett <BodyLargeMedium as="span">fysiskt besök</BodyLargeMedium> hos
                  en gynekolog i Stockholm kan du boka tid på vår gynmottagning på Torsplan. Det gör du genom att ringa
                  till 08-60 90 270. Vår mottagning är regionsansluten, vilket innebär att du betalar en patientavgift
                  på 275 kr, frikort gäller.{' '}
                  <RichTextLink
                    href="https://estercare.com/gynmottagning-torsplan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Läs mer här.
                  </RichTextLink>
                </StyledListItem>
                <StyledListItem>
                  EsterCares digitala mottagning. Du kan boka ett
                  <BodyLargeMedium as="span"> digitalt besök</BodyLargeMedium> hos en gynekolog via vår EsterCare-app.
                  Vår mottagning är regionsansluten, vilket innebär att du betalar en patientavgift på 100 kr för ett
                  digitalt besök, frikort gäller.{' '}
                  <RichTextLink href="https://estercare.com/sa-funkar-varden" target="_blank" rel="noopener noreferrer">
                    Läs mer här.
                  </RichTextLink>
                </StyledListItem>
              </BodyLarge>
            </TextContainer>
          </InnerContainer>
        </Container>
      </AnalysisLayout>
    </>
  )
}

const Container = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`

const InnerContainer = styled.div`
  width: 640px;
  max-width: 640px;
`

const TextContainer = styled.div`
  border-radius: 16px;
  padding: ${theme.spacing.xlarge}px ${theme.spacing.mediumLarge}px;
  background-color: ${theme.color.white};
  margin-bottom: ${theme.spacing.xxlarge}px;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding: ${theme.spacing.medium}px ${theme.spacing.small}px;
  }
`

const StyledListItem = styled.li`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const StyledWaveWrapper = styled.div`
  z-index: 0;
  margin-top: -${theme.spacing.xxlarge}px;
`

const StyledBodyXlMedium = styled(BodyXlMedium)`
  color: ${theme.color.white};
  margin-bottom: ${theme.spacing.medium}px;
  margin-left: ${theme.spacing.tiny}px;
`

const PlanContainer = styled.div`
  margin-bottom: ${theme.spacing.xxlarge}px;
`
