import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodyXl } from '../components/styles/Typography'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const ErrorPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const errorMessage = location.state?.errorMessage || 'An error occurred'

  useEffect(() => {
    if (errorMessage.includes('Missing Refresh Token')) {
      logout({
        openUrl() {
          navigate('/login')
        },
      })
    }
  }, [errorMessage, logout, navigate])

  if (errorMessage.includes('Missing Refresh Token')) {
    return null
  }

  return (
    <ErrorWrapper>
      <BodyXl>{errorMessage}</BodyXl>
    </ErrorWrapper>
  )
}

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.color.plum};
`
