import { FC } from 'react'
import { TextInput } from './Text'

interface FormattedZipCodeInputProps {
  value: string
  backgroundColor?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}

export const ZipCodeInput: FC<FormattedZipCodeInputProps> = ({ value, backgroundColor, onChange, error }) => {
  const formatZipCode = (val: string) => {
    const digits = val.replace(/\D/g, '').slice(0, 5)
    return digits.length > 3 ? `${digits.slice(0, 3)} ${digits.slice(3)}` : digits
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, '')
    onChange({ ...e, target: { ...e.target, value: rawValue } })
  }

  return (
    <TextInput
      label="Ditt postnummer"
      backgroundColor={backgroundColor}
      placeholder="123 45"
      value={formatZipCode(value)}
      onChange={handleChange}
      maxLength={6}
      inputMode="numeric"
      pattern="[0-9]*"
      error={error}
    />
  )
}
