import { HeartPalpitation, Home, Paper, QuestionMark, Search, StethoscopePlus } from '../../components/icons'
import { MenuItem } from './types'
import { useSelectScreeningResultStatus } from '../../state/selectors'
import { ScreeningResultStatus } from '../../api/ester-b2b-api/private/screeningResult/types'

export const useEmployeeMenu = (): MenuItem[] => {
  const selectedScreeningResultStatus = useSelectScreeningResultStatus()
  return [
    { name: 'Hem', path: '/portal', icon: Home },
    ...(selectedScreeningResultStatus === ScreeningResultStatus.HANDLED
      ? [{ name: 'Din hälsa', path: '/portal/din-halsa/analys', icon: HeartPalpitation }]
      : []),
    { name: 'Hälsotjänster', path: '/portal/halsotjanster', icon: StethoscopePlus },
    { name: 'Utbildning', path: '/portal/utbildning', icon: Paper },
    { name: 'Kunskapsbank', path: '/portal/kunskapsbank', icon: Search },
    { name: 'Support', path: '/portal/support', icon: QuestionMark },
  ]
}
