import { adminMenu } from './adminMenu'
import { componentLibraryMenu, isComponentLibraryMenu } from './componentLibraryMenu'
import { useEmployeeMenu } from './employeeMenu'
import { MenuItem } from './types'

export const useGetMenu = (showAdminMenu: boolean, currentRoute?: string): MenuItem[] => {
  const employeeMenu = useEmployeeMenu()
  if (currentRoute && isComponentLibraryMenu(currentRoute)) {
    return componentLibraryMenu
  } else if (showAdminMenu) {
    return adminMenu
  } else {
    return employeeMenu
  }
}
