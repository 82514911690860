import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 24
const DEFAULT_COLOR = theme.color.black

type Props = {
  left?: boolean
} & IconProps

export const Caret = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, left = false, ...rest }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: left ? 'none' : 'rotate(180deg)' }}
    {...rest}
  >
    <path
      d="M16.875 6.75L10.125 13.5L16.875 20.25"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
