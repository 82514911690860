import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { ReactNode } from 'react'

interface AnalysisLayoutProps {
  children: ReactNode
  backgroundColor?: string
  paddingTop?: number
}

export const AnalysisLayout = ({ children, backgroundColor, paddingTop }: AnalysisLayoutProps) => {
  return (
    <AnalysisLayoutContainer $backgroundColor={backgroundColor} $paddingTop={paddingTop}>
      {children}
    </AnalysisLayoutContainer>
  )
}

const AnalysisLayoutContainer = styled.div<{
  $backgroundColor?: string
  $paddingTop?: number
}>`
  background: ${({ $backgroundColor }) => $backgroundColor || theme.color.beigeLight};
  padding-top: ${({ $paddingTop }) => $paddingTop || theme.spacing.xxlarge}px;
  padding-bottom: ${theme.spacing.xxlarge}px;
  padding-left: ${theme.spacing.xlarge}px;
  padding-right: ${theme.spacing.xlarge}px;
  width: 100%;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding-top: ${({ $paddingTop }) => $paddingTop || theme.spacing.large}px;
    padding-bottom: ${theme.spacing.large}px;
    padding-left: ${theme.spacing.small}px;
    padding-right: ${theme.spacing.small}px;
  }
`
