import { Slider as MuiSlider, Stack } from '@mui/material'
import { BodySmall } from '../styles/Typography'
import styled from 'styled-components'
import { useState } from 'react'
import { theme } from '../../theme/theme'

type Props = {
  disabled?: boolean
  minLabel?: string
  maxLabel?: string
  minValue: number
  maxValue: number
  color?: string
}

export const Slider = ({
  disabled = false,
  minLabel,
  maxLabel,
  minValue,
  maxValue,
  color = theme.color.plumMid,
}: Props) => {
  const [value, setValue] = useState<number>(2)

  return (
    <Stack>
      <ValueContainer>
        <BodySmall> {value}</BodySmall>
      </ValueContainer>

      <StyledSlider
        min={minValue}
        max={maxValue}
        aria-label="Volume"
        value={value}
        onChange={(_, newVal) => setValue(newVal as number)}
        disabled={disabled}
        $color={disabled ? undefined : color}
      />
      <LabelContainer>
        <BodySmall>{minLabel || minValue}</BodySmall>
        <BodySmall>{maxLabel || maxValue}</BodySmall>
      </LabelContainer>
    </Stack>
  )
}

const StyledSlider = styled(MuiSlider)<{ $color?: string }>`
  && {
    color: ${({ $color }) => $color};

    & .MuiSlider-thumb {
      box-shadow: none;
    }
  }
`

const ValueContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${theme.spacing.medium}px;

  & ${BodySmall} {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${`${theme.color.plum}20`};
    color: ${theme.color.plum};
    width: ${theme.spacing.xlarge}px;
    height: ${theme.spacing.xlarge}px;
    border-radius: 50%;
  }
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.xsmall}px;
`
