import React, { useState, ReactNode, useRef } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Close } from './icons'
import { BodyTiny } from './styles/Typography'
import { Popover as MuiPopover } from '@mui/material'
import { isLowerThenCustomBreakpoint } from '../utils/devices'
import { useWindowWidth } from '../hooks/useWindowWidth'

type Props = {
  children: ReactNode
  text: string
}

export const Popover = ({ children, text }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  const width = useWindowWidth()
  const isTabletOrMobile = isLowerThenCustomBreakpoint(width, 769)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isTabletOrMobile || !open) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isTabletOrMobile) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMouseLeave = () => {
    if (!isTabletOrMobile) {
      setAnchorEl(null)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div ref={containerRef}>
      <TriggerWrapper
        ref={triggerRef}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </TriggerWrapper>
      <StyledMuiPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: '10px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            },
          },
        }}
        onMouseEnter={
          !isTabletOrMobile
            ? () => {
                if (triggerRef.current) {
                  setAnchorEl(triggerRef.current)
                }
              }
            : undefined
        }
        onMouseLeave={!isTabletOrMobile ? handleClose : undefined}
      >
        <ArrowUp />
        <PopoverContentWrapper>
          <BodyTiny>{text}</BodyTiny>
          {isTabletOrMobile && (
            <CloseButton onClick={handleClose}>
              <Close size={theme.spacing.medium} />
            </CloseButton>
          )}
        </PopoverContentWrapper>
      </StyledMuiPopover>
    </div>
  )
}

const StyledMuiPopover = styled(MuiPopover)`
  & .MuiPopover-paper {
    overflow: visible;
    background: white;
  }
`

const ArrowUp = styled.div`
  position: absolute;
  top: -${theme.spacing.xsmall}px;
  left: 50%;
  height: ${theme.spacing.xsmall}px;
  &:before {
    background: ${theme.color.white};
    content: '';
    position: absolute;
    width: ${theme.spacing.small}px;
    height: ${theme.spacing.small}px;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: 50%;
  }
`

const TriggerWrapper = styled.div`
  display: flex;
  cursor: pointer;
`

const PopoverContentWrapper = styled.div`
  padding: ${theme.spacing.medium}px;
  padding-right: ${theme.spacing.large}px;
  width: 366px;
  @media (max-width: 400px) {
    width: calc(100vw - 32px);
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: ${theme.spacing.small}px;
`
