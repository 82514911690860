import { ISpecialistFields } from '@estercare/ester-shared'
import styled from 'styled-components'
import { Entry } from 'contentful'
import { Staff } from './Staff'
import { PreviewGuard } from '../PreviewGuard'
import { Scroll } from './Scroll'
import { theme } from '../../theme/theme'

interface Props {
  specialists?: Entry<ISpecialistFields>[]
  fullWidthStyle?: boolean
  backgroundColor?: string
  inactiveClick?: boolean
}

export const StaffScroller = ({ specialists, fullWidthStyle = false, backgroundColor, inactiveClick }: Props) => {
  if (!Array.isArray(specialists)) return null
  const extraClasses = `${specialists.length <= 3 ? 'centered' : ''} ${fullWidthStyle ? 'no-overflow' : ''}`

  return (
    <StyledScroll className={extraClasses}>
      {specialists.map(({ fields: { firstName, lastName, jobTitle, image, slug, specialistId } }, index) => {
        if (!image || !slug) return <PreviewGuard key={index} />

        return (
          <Staff
            key={index}
            name={`${firstName} ${lastName}`}
            image={image}
            jobTitle={jobTitle}
            slug={slug}
            specialistId={specialistId}
            backgroundColor={backgroundColor}
            inactiveClick={inactiveClick}
          />
        )
      })}
    </StyledScroll>
  )
}

export const StyledScroll = styled(Scroll)`
  overflow-y: hidden;
  div {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: ${theme.breakpoint.xlarge}px) {
    &.no-overflow {
      overflow: visible;
      justify-content: center;
    }
  }

  &.centered {
    display: flex;
    padding-left: 0px;
    @media screen and (min-width: ${theme.breakpoint.medium}px) {
      padding-left: ${theme.spacing.large}px;
    }
    @media screen and (max-width: ${theme.breakpoint.medium}px) {
      justify-content: center;
    }
  }

  &.centered:not(.no-overflow) {
    @media screen and (min-width: ${theme.breakpoint.medium}px) {
      justify-content: start;
    }
  }
`
