import { useAuth0 } from '@auth0/auth0-react'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import { Screening } from './types'
import { getScreening } from './screening'

export const useGetScreeningQuery = (): UseQueryResult<Screening, Error> => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery({
    queryKey: [QueryKeys.Screening, 'private'],
    queryFn: async (): Promise<Screening> => {
      const accessToken = await getAccessTokenSilently()
      return await getScreening({ accessToken })
    },
  })
}
