import { ComponentLayout } from '../../../../components/layouts/ComponentLayout'
import styled from 'styled-components'
import { Title4 } from '../../../../components/styles/Typography'
import { theme } from '../../../../theme/theme'
import { Arrow, Close, Caret } from '../../../../components/icons'
import { Stack } from '@mui/material'
import { OnClickButton } from '../../../../components/buttons/onclick/OnClickButton'
import { useNavigate } from 'react-router-dom'
import { isLowerThenCustomBreakpoint } from '../../../../utils/devices'
import { useWindowWidth } from '../../../../hooks/useWindowWidth'
import { ScreeningTitle } from '../../../../components/ScreeningTitle'

export const Questions = () => {
  const width = useWindowWidth()
  const isTabletOrMobile = isLowerThenCustomBreakpoint(width, 769)

  const navigate = useNavigate()
  const iconSize = isTabletOrMobile ? 24 : 32
  const ArrowBack = isTabletOrMobile ? Caret : Arrow

  const handleCloseClick = () => {
    navigate('/portal/halsotjanster/screening')
  }

  return (
    <>
      <HeaderContainer>
        <Section flexDirection="row" justifyContent="space-between">
          <ArrowBack left size={iconSize} onClick={handleCloseClick} color={theme.color.plum} />
          <Title4>Del 1 - Frågor om dig</Title4>
          <Close size={iconSize} onClick={handleCloseClick} color={theme.color.plum} />
        </Section>
      </HeaderContainer>

      <ComponentLayout
        noMaxWidth
        paddingTop={isTabletOrMobile ? theme.spacing.xsmall : theme.spacing.xlarge}
        style={{
          height: '100%',
        }}
      >
        <Section justifyContent="space-between">
          <div>
            <ScreeningTitle
              title="Vad är ditt personnummer?"
              description={{
                text: 'Varför behöver vi veta detta?',
                infoText:
                  'Ditt personnummer behövs för att kunna koppla dina svar till dig som individ. Dina svar kommer att behandlas konfidentiellt och anonymt.',
              }}
            />
            <p>Answers</p>
          </div>

          <ButtonContainer>
            <OnClickButton text="Nästa fråga" onClick={() => {}} icon={<Arrow color={theme.color.white} size={15} />} />
          </ButtonContainer>
        </Section>
      </ComponentLayout>
    </>
  )
}

const HeaderContainer = styled.div`
  padding: ${theme.spacing.large}px ${theme.spacing.medium}px;

  & svg {
    cursor: pointer;
  }

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    border-bottom: 1px solid ${theme.color.beigeDark};
  }
`

const Section = styled(Stack)`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
`

const ButtonContainer = styled.div`
  & > button {
    width: 100%;
  }

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    margin-left: auto;
  }
`
