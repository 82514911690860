import { FC, ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const ComponentLayout: FC<{
  children: ReactNode | ReactNode[]
  backgroundColor?: string
  paddingTop?: number
  paddingBottom?: number
  noMaxWidth?: boolean
  style?: CSSProperties
}> = ({ children, backgroundColor, paddingTop, paddingBottom, noMaxWidth, style }) => {
  return (
    <ComponentLayoutContainer
      $backgroundColor={backgroundColor}
      $paddingTop={paddingTop?.toString()}
      $paddingBottom={paddingBottom?.toString()}
      $noMaxWidth={noMaxWidth}
      style={style}
    >
      {children}
    </ComponentLayoutContainer>
  )
}

const ComponentLayoutContainer = styled.div<{
  $backgroundColor?: string
  $paddingTop?: string
  $paddingBottom?: string
  $noMaxWidth?: boolean
}>`
  background: ${({ $backgroundColor }) => $backgroundColor || theme.color.beigeLight};
  padding-top: ${({ $paddingTop }) => $paddingTop || theme.spacing.xxlarge}px;
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom || theme.spacing.xxlarge}px;
  padding-left: ${theme.spacing.xlarge}px;
  padding-right: ${theme.spacing.xlarge}px;
  max-width: ${({ $noMaxWidth }) => ($noMaxWidth ? '100%' : '1440px')};
  width: 100%;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding-top: ${({ $paddingTop }) => $paddingTop || theme.spacing.large}px;
    padding-bottom: ${({ $paddingBottom }) => $paddingBottom || theme.spacing.large}px;
    padding-left: ${theme.spacing.mediumLarge}px;
    padding-right: ${theme.spacing.mediumLarge}px;
  }
`
