import { useAuth0 } from '@auth0/auth0-react'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import { getScreeningResultStatus } from './screeningResult'

export const useGetScreeningResultStatusQuery = (): UseQueryResult<{ screeningResultStatus: string | null }, Error> => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery({
    queryKey: [QueryKeys.ScreeningResult, 'private', 'status'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await getScreeningResultStatus({ accessToken })
    },
  })
}
