import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const Scroll = styled.div`
  cursor: grab;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  max-width: 100vw;
  scroll-snap-type: x mandatory;
  justify-content: space-between;

  > * {
    margin-left: ${theme.spacing.large}px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: ${theme.spacing.large}px;
    }
  }

  @media screen and (min-width: ${theme.breakpoint.xlarge}px) {
    &.no-overflow {
      cursor: default;
    }
  }
`
