import { useState } from 'react'
import { FAQ } from '../../components/Faq'
import { Radios } from '../../components/inputs/Radios'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { useGetEntryById } from '../../api/contentful/contentfulQueries'
import { Title3 } from '../../components/styles/Typography'
import { isIFAQ } from '@estercare/ester-shared'
import { theme } from '../../theme/theme'

export const ContentfulBlocks = () => {
  const faq = useGetEntryById('ojxTHwOE2LlV22jFQ6n1B').data

  const [imageAlignment, setImageAlignment] = useState('Left')
  const imageAlignments = ['Left', 'Right']

  const [faqBackGroundOption, setfaqBackGroundOptions] = useState('beigeLight')
  const faqBackGroundOptions = ['beigeLight', 'beigeDark']

  return (
    <ComponentLayout>
      <>
        <Radios
          options={imageAlignments}
          setChange={setImageAlignment}
          component="FAQ"
          backgroundColor={theme.color.beigeDark}
        />
        <Radios
          options={faqBackGroundOptions}
          setChange={setfaqBackGroundOptions}
          component="FAQbg"
          backgroundColor={theme.color.beigeDark}
        />
        <Title3>FAQ-Section Block</Title3>
        {isIFAQ(faq) && (
          <FAQ
            title={faq.fields.title}
            faqItems={faq.fields.faqItems}
            imageAlignment={imageAlignment === 'Right'}
            backgroundColor={faqBackGroundOption === 'beigeLight' ? 'beigeLight' : 'beigeDark'}
          />
        )}
      </>
    </ComponentLayout>
  )
}
