import { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodyLarge } from './styles/Typography'
import { Caret } from './icons/Caret'
import { Asset } from 'contentful'

interface Props {
  title: string | ReactNode // Allows for custom title component or a simple string
  children: ReactNode[] | ReactNode
  icon?: Asset
}

export const Accordion: FC<Props> = ({ title, children, icon }) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Container>
      <HeaderContainer onClick={() => setOpen(!open)}>
        <TitleContainer>
          {icon?.fields?.file && (
            <ImageContainer>
              <StyledPlumImage src={icon?.fields.file?.url} alt={icon?.fields.description} />
            </ImageContainer>
          )}
          <BodyLarge>{title}</BodyLarge>
        </TitleContainer>
        <RotatingCaret $open={open} />
      </HeaderContainer>
      <ContentContainer className={`${open && 'open'}`}>
        <div>{children}</div>
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: ${theme.spacing.small}px;
  background: ${theme.color.white};
  border: 1px solid ${theme.color.grey2};
  border-radius: 16px;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 3px 9px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding: ${theme.spacing.small}px;
  }
`

const RotatingCaret = styled(Caret)<{ $open: boolean }>`
  transform: rotate(90deg);
  transition: all 0.6s ease;

  ${({ $open }) => $open && `transform: rotate(-90deg);`}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.small}px ${theme.spacing.mediumLarge}px;
  cursor: pointer;

  span {
    margin-right: ${theme.spacing.mediumLarge}px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  padding: 0 ${theme.spacing.mediumLarge}px;
  transition: grid-template-rows 0.3s ease;
  margin: 0;
  color: ${theme.color.grey5};

  > div {
    overflow: hidden;
  }

  > p {
    margin-bottom: ${theme.spacing.medium}px;
  }
  blockquote > p {
    all: inherit;
  }

  &.open {
    grid-template-rows: 1fr;
    padding-bottom: ${theme.spacing.small}px;
  }
`

const ImageContainer = styled.div`
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background: ${theme.color.plumlight};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${theme.spacing.xsmall}px;
`

const StyledPlumImage = styled.img`
  color: ${theme.color.plum};
  filter: brightness(0) saturate(100%) invert(12%) sepia(38%) saturate(2663%) hue-rotate(304deg) brightness(97%)
    contrast(96%);
`
