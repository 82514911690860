import { FC } from 'react'
import Dropzone, { Accept } from 'react-dropzone'
import { BodySmall } from '../styles/Typography'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { CheckRing } from '../icons/CheckRing'
import { LoadingSpinner } from '../LoadingSpinner'

export type LoadingState = 'LOADING' | 'FAILED' | 'SUCCESS' | null

interface Props {
  acceptedFiles: Accept
  onDrop: (files: File[]) => void
  fileUploadState: LoadingState
  maxFiles?: number
  errorMessage?: string
}

export const DropZone: FC<Props> = ({ acceptedFiles, onDrop, fileUploadState, maxFiles = 1, errorMessage }) => {
  return (
    <Dropzone onDrop={onDrop} accept={acceptedFiles} maxFiles={maxFiles}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropZoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && !fileUploadState && (
            <BodySmall>Dra filen hit eller klicka i rutan för att importera.</BodySmall>
          )}
          {isDragActive && !isDragReject && <BodySmall>Släpp filen här...</BodySmall>}
          {isDragReject && <BodySmall>Den här filtypen stöds inte.</BodySmall>}
          {fileUploadState === 'LOADING' && (
            <StateContainer>
              <LoadingSpinner size={48} color={theme.color.black} />
              <BodySmall>Laddar upp filen...</BodySmall>
            </StateContainer>
          )}
          {fileUploadState === 'FAILED' && (
            <StateContainer>
              <BodySmall>Det gick inte att ladda upp filen, vänligen försök igen.</BodySmall>
              {errorMessage && <BodySmall>{errorMessage}</BodySmall>}
            </StateContainer>
          )}
          {fileUploadState === 'SUCCESS' && (
            <StateContainer>
              <CheckRing size={48} />
              <BodySmall>Uppladdning klar!</BodySmall>
            </StateContainer>
          )}
        </DropZoneContainer>
      )}
    </Dropzone>
  )
}

const DropZoneContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 416px;
  height: 214px;
  border: 1px dashed ${theme.color.grey3};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`

const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.medium}px;
  padding: ${theme.spacing.xsmall}px;
`
