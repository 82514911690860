import { Asset } from 'contentful'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { BodyTiny } from '../styles/Typography'
import { SuperImage } from '../SuperImage'
import { Link } from 'react-router-dom'

export interface Props {
  name: string
  jobTitle?: string
  image: Asset
  slug: string
  specialistId?: string
  backgroundColor?: string
  inactiveClick?: boolean
}

export const Staff = ({ name, jobTitle, image, slug, backgroundColor, specialistId, inactiveClick }: Props) => {
  const diameterMobile = 110
  const diameterDesktop = diameterMobile + 50
  const href = specialistId && !inactiveClick ? `/medarbetare/${slug}` : '#'
  const color = backgroundColor === 'plum' ? theme.color.white : theme.color.black

  return (
    <StyledLink
      to={href}
      onClick={(event) => event.preventDefault()}
      draggable={false}
      $unClickable={Boolean(inactiveClick)}
    >
      <StaffContainer $color={color}>
        <ImgContainer $diameterMobile={diameterMobile} $diameterDesktop={diameterDesktop}>
          <SuperImage asset={image} fill />
        </ImgContainer>
        <StyledBodyTiny>{name}</StyledBodyTiny>
        <StyledBodyTiny className="jobTitle">{jobTitle}</StyledBodyTiny>
      </StaffContainer>
    </StyledLink>
  )
}

const StyledLink = styled(Link)<{ $unClickable: boolean }>`
  margin-top: ${theme.spacing.small}px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
`

const ImgContainer = styled.div<{ $diameterMobile: number; $diameterDesktop: number }>`
  height: ${(p) => p.$diameterMobile}px;
  width: ${(p) => p.$diameterMobile}px;
  border-radius: ${(p) => p.$diameterMobile}px;
  margin: auto auto ${theme.spacing.small}px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${theme.breakpoint.small}px) {
    height: ${(p) => p.$diameterDesktop}px;
    width: ${(p) => p.$diameterDesktop}px;
    border-radius: ${(p) => p.$diameterDesktop}px;
  }
`

const StaffContainer = styled.div<{ $color: string }>`
  grid-template-rows: auto;
  pointer-events: none;
  color: ${theme.color.grey5};
  justify-items: center;
  color: ${({ $color }) => $color};
`

const StyledBodyTiny = styled(BodyTiny)`
  margin: auto;
  text-align: center;
  max-width: 100px;

  @media screen and (min-width: ${theme.breakpoint.small}px) {
    max-width: 140px;
  }
`
