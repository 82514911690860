import { Outlet } from 'react-router-dom'
import { useGetScreeningResultStatusQuery } from '../../api/ester-b2b-api/private/screeningResult/screeningResultQueries'
import { useEffect } from 'react'
import { useScreeningStore } from '../../state/store'
import { useGetScreeningQuery } from '../../api/ester-b2b-api/private/screening/screeningQueries'

export const PortalLayout = () => {
  const screening = useGetScreeningQuery().data
  const screeningResultStatus = useGetScreeningResultStatusQuery().data?.screeningResultStatus
  const { setScreening, setScreeningResultStatus } = useScreeningStore()

  useEffect(() => {
    screening && setScreening(screening)
    screeningResultStatus && setScreeningResultStatus(screeningResultStatus)
  }, [screening, screeningResultStatus, setScreening, setScreeningResultStatus])

  return <Outlet />
}
