import { appConfig } from '../../../../config'
import { Screening } from './types'

export const getScreening = async ({ accessToken }: { accessToken: string }): Promise<Screening> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/screening`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const data = await res.json()
  return data
}
