import { FC, useState, useEffect } from 'react'
import { useGetMenu } from '../../utils/menus/getMenu'
import { DesktopSidebar } from './DesktopSideBar'
import { MobileSideBar } from './MobileSideBar'
import { theme } from '../../theme/theme'
import { Womb } from '../icons/Womb'
import styled from 'styled-components'
import { Menu } from '../icons/Menu'
import { Close } from '../icons/Close'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import { isDesktop, isMobile } from '../../utils/devices'

import { Link, useLocation } from 'react-router-dom'
import { useSelectIsAdmin } from '../../state/selectors'

export const Sidebar: FC = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false)
  const location = useLocation()
  const width = useWindowWidth()
  const showAdminMenu = location.pathname.startsWith('/admin')
  const menu = useGetMenu(showAdminMenu, location.pathname)
  const mobileIconSize = 32
  const desktopIconSize = 40
  const iconSize = isDesktop(width) ? desktopIconSize : mobileIconSize
  const desktopSideBarHeaderHeight = 80
  const headerHeight = isDesktop(width) ? desktopSideBarHeaderHeight : theme.spacing.mobileHeaderHeight
  const isAdmin = useSelectIsAdmin()
  const mobile = isMobile(width)

  useEffect(() => {
    if (mobile) {
      setOpenMobileMenu(false)
    }
  }, [location, mobile])

  return (
    <SideBarContainer>
      <Header $height={headerHeight} $isDesktop={isDesktop(width)} $mobileMenuOpen={openMobileMenu}>
        <StyledLink to={isAdmin ? '/admin' : '/portal'}>
          <Womb color={theme.color.plum} size={iconSize} />
        </StyledLink>

        <MobileMenuButtons onClick={() => setOpenMobileMenu(!openMobileMenu)}>
          {openMobileMenu ? <Close size={24} color={theme.color.plum} /> : <Menu size={24} color={theme.color.plum} />}
        </MobileMenuButtons>
      </Header>

      <MobileSideBar menuItems={menu} open={openMobileMenu} />
      <DesktopSidebar menuItems={menu} />
    </SideBarContainer>
  )
}

const MobileMenuButtons = styled.div`
  display: none;
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    display: flex;
    position: absolute;
    right: ${theme.spacing.medium}px;
  }
`
const Header = styled.div<{ $height: number; $isDesktop: boolean; $mobileMenuOpen: boolean }>`
  z-index: 9999;
  height: ${(props) => props.$height}px;
  border-bottom: ${(props) => !props.$isDesktop && !props.$mobileMenuOpen && `1px solid ${theme.color.grey2}`};
  display: flex;
  align-items: center;
  background-color: ${theme.color.beigeLight};
  & > svg {
    margin-left: ${theme.spacing.mediumLarge}px;
  }
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    position: absolute;
    width: 100%;
  }
`

const SideBarContainer = styled.div`
  z-index: 9998;
  background-color: ${theme.color.beigeLight};
  flex-direction: column;
  display: flex;
  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    border-right: 1px ${theme.color.grey2} solid;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.mediumLarge}px;
  text-decoration: none;
`
